import { httpClient } from './httpClient.js';

const
    LISTPAYMENTS = 'dashboard/payments',
    GETPAYMENTDETAILS = 'dashboard/payments/';

const listPayment = async (listParams) => {
    return await httpClient.dashboardService.get(LISTPAYMENTS, { "params": listParams});
}
const getPayment = (id, expandParams) => httpClient.dashboardService.get(GETPAYMENTDETAILS + id, { "params": expandParams});

export {
    listPayment,
    getPayment
};
