<template>
  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardBody v-if="showPaymentDetails">
          <div class="details-header row">
            <CCol col="10">
              <h5>{{ $t("PAYMENTS.TABLE.3") }}:</h5>
              <div class="details-header-title">{{ $route.params.id }}</div>
            </CCol>
            <CCol col="2">
                <CButton
                v-show="selected.status === 'completed' && selected.payment_details_type !== 'card_details' && selected.amount >= 10000"
                class="refund-button"
                @click="goTo(`/refunds/new?payment_id=${$route.params.id}`)">{{
                $t("REFUND.REFUND")
              }}</CButton> 
            </CCol>
          </div>          
                    
          <div class="table-responsive mb-3">
            <table class="table">
              <tbody>
                <tr>
                  <td class="bold">{{ $t("PAYMENTS.TABLE.4") }}</td>
                  <td>
                    <div v-on:click="goTo('/orders/' + selected.order_id)">
                      <p class="view-more">{{ selected.order_id }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.6") }}</td>
                  <td>{{ selected.order.order_ref_id }}</td>
                </tr>
                <tr v-if="selected.settlement_id !== ''">
                  <td class="bold">{{ $t("ORDERS.TABLE.14") }}</td>
                  <td>
                    <div
                      v-on:click="
                        goTo('/settlements/' + selected.settlement_id)
                      "
                    >
                      <p class="view-more">{{ selected.settlement_id }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENTS.TABLE.12") }}</td>
                  <td>{{ formatAmount(selected.amount) }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENTS.TABLE.13") }}</td>
                  <td>{{ formatAmount(selected.discount) }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("PAYMENTS.TABLE.14") }}</td>
                  <td>{{ formatAmount(selected.paid_amount) }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.7") }}</td>
                  <td>{{ selected.order.currency }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.1") }}</td>
                  <td>
                    <CBadge :color="getPaymentBadge(selected.status)">{{
                      selected.status
                    }}</CBadge>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.4") }}</td>
                  <td>{{ selected.created_at }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.9") }}</td>
                  <td>{{ selected.updated_at }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.TABLE.5") }}</td>
                  <td>
                    <div
                      v-on:click="goTo('/customers/' + selected.customer.id)"
                    >
                      <p class="view-more">{{ selected.customer.id }}</p>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.CSV.NAME") }}</td>
                  <td>{{ selected.customer.given_name }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.CSV.PAYMENTCHANNEL") }}</td>
                  <td>{{ getPayType(selected.payment_details_type) }}</td>
                </tr>
                <tr>
                  <td class="bold">{{ $t("ORDERS.CSV.SUBPAYMENTCHANNEL") }}</td>
                  <td>{{ selected.method_id }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </CCardBody>
        <CCardFooter>
          <CRow align-horizontal="between px-3">
            <CButton color="durianprimary" @click="goBack">{{
              $t("COMMON.BACK_BUTTON")
            }}</CButton>            
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { getPayment } from "@/api/payment.api.js";

export default {
  name: "PaymentDetails",
  data() {
    return {
      selected: [],
      showPaymentDetails: false,
    };
  },
  methods: {
    getPaymentDetailsByID() {
      getPayment(
        this.$route.params.id,
        this.generateExpandParams(["customer", "order"])
      )
        .then((response) => {
          const err = this.verifyHttpResponse(response);
          if (err === false) {
            this.selected = response.data.data;
            this.selected["expiration_date"] = this.formatDate(
              this.selected["expiration_date"]
            );
            this.selected["created_at"] = this.formatDate(
              this.selected["created_at"]
            );
            this.selected["updated_at"] = this.formatDate(
              this.selected["updated_at"]
            );
            delete this.selected["payment_ds_ref_id"];
            delete this.selected["merchant_id"];
            delete this.selected["metadata"];
            this.showPaymentDetails = true;
          }
        })
        .catch(this.handleAPIError);
    },
    getLabelKey(key) {
      switch (key) {
        case "id":
          return this.$t("PAYMENTS.TABLE.3");
        case "order_id":
          return this.$t("PAYMENTS.TABLE.4");
        case "payment_ref_id":
          return this.$t("PAYMENTS.TABLE.7");
        case "amount":
          return this.$t("PAYMENTS.TABLE.2");
        case "status":
          return this.$t("PAYMENTS.TABLE.1");
        case "is_live":
          return this.$t("PAYMENTS.TABLE.8");
        case "expiration_date":
          return this.$t("PAYMENTS.TABLE.9");
        case "payment_details_type":
          return this.$t("PAYMENTS.TABLE.5");
        case "created_at":
          return this.$t("PAYMENTS.TABLE.6");
        default:
          return key;
      }
    },
  },
  computed: {
    visibleData() {
      const paymentDetails = this.selected
        ? Object.entries(this.selected)
        : [["id", "Not found"]];
      return paymentDetails.map(([key, value]) => {
        if (key == "is_live") {
          value = this.getType(value);
        }
        if (key == "payment_method") {
          value = this.getPayType(value);
        }
        key = this.getLabelKey(key);
        return { key, value };
      });
    },
    detailFields() {
      if (this.visible) {
        return [
          { key: "key", label: this.selected.id, _style: "width:150px" },
          { key: "value", label: "", _style: "width:150px;" },
        ];
      }
    },
  },
  mounted() {
    this.getPaymentDetailsByID();
  },
};
</script>
<style>
.refund-button, .refund-button:hover {
  border: 1px solid #725CF6;
  box-shadow: 0px 2px 4px 1px rgba(114, 92, 246, 0.3);
  border-radius: 6px;
  width: 107px;
  height: 36px;
  color:#725CF6;
  font-weight: bold;
  font-size: 16px;
  float: right;
}

.refund-button:hover {
  background-color: #725CF6;
  color: white;
}
</style>